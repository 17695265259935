import request from "@/utils/request";
// 获取侧边栏及权限
export const LoadMenu = () => {
    return request.get('/common/loadRole/authority')
}
// 登录
export const Login = (params) => {
    return request.post('/common/student/captcha/login', params)
}
// 退出
export const LogOut = () => {
    return request.get('/common/exit')
}
// 重置密码
export const ResetPwd = (params) => {
    return request.post('/common/restPwd', params)
}
// 获取验证码
export const getCaptcha = (params) => {
    return request.post('/common/loadCaptcha', params)
}
// 上传图片
export const upload = (params) => {
    return request.post('/admin/file/upload/file', params)
}
// 获取地区
export const LoadArea = () => {
    return request.get('/common/loadArea')
}