// 节流指令  使用方法: v-click="函数", 如需传参的话:v-click="() => 函数" 务必要把函数return出去 不然会自己执行
const click = {
    mounted: function (el, binding) {
        const callback = binding.value
        el.callback = callback
        el.time = 1000
        el.addEventListener('click', () => {
            const nowTime = new Date().getTime()
            if (!el.preTime || nowTime - el.preTime > el.time) {
                el.preTime = nowTime
                el.callback()
            }
        })
    },
    beforeUpdate: function (el, binding) {
        const callback = binding.value
        el.callback = callback
        el.time = 1000
    }
}

export default { click }