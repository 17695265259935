import axios from "axios";
import router from "@/router/index";
import store from "@/store/index";
import { getToken } from "@/utils/auth";
import { ElMessage } from "element-plus";
const service = axios.create({
    baseURL: '/v1/cloud',
    timeout: 60 * 60 * 1000
})
service.interceptors.request.use(
    config => {
        // get请求映射params参数
        if (config.method === 'get' && config.params) {
            let url = config.url + '?' + tansParams(config.params);
            url = url.slice(0, -1);
            config.params = {};
            config.url = url;
        }
        if (getToken) {
            config.headers['Authorization'] = getToken()
        }
        return config
    },
    error => {
        Promise.reject(error)
    }
)

service.interceptors.response.use(
    res => {
        const code = res.data.code
        if (code == 200) {
            return res.data.data
        } else if (code == 400) {
            ElMessage.error(res.data.msg)
            return Promise.reject(res.data.msg)
        }
    },
    error => {
        let code = error.response.status
        let data = error.response.data
        console.log(error)
        if (code == 400) {
            ElMessage.error(error.response.data.message)
        } else if (code == 500) {
            ElMessage.error('网络异常!')
        } else if (code == 401) {
            store.commit('user/RESET_USER')
            ElMessage.error(store.state.user.login ? '登录失效,请重新登录!' : '请先登录!')
            router.replace(`/login?redirect=${router.currentRoute.value.fullPath}`)
        } else if (code == 403) {
            ElMessage.error('无权限请联系管理员!')
        }
        return Promise.reject(data)
    }
)

const get = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        service({
            url,
            method: 'get',
            params
        }).then(res => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}
const post = (url, data = {}) => {
    return new Promise((resolve, reject) => {
        service({
            url,
            method: 'post',
            data
        }).then(res => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}
const request = { get, post }
export default request
function tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && value !== "" && typeof (value) !== "undefined") {
            if (typeof value === 'object') {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        result += subPart + encodeURIComponent(value[key]) + "&";
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&";
            }
        }
    }
    return result
}