import { setToken, removeToken, setArea, removeArea } from "@/utils/auth";
import { Login, LogOut, ResetPwd, LoadArea } from "@/api/user";
import axios from "axios";
const state = () => ({
    user: {},
    area: [],
    setIsShowQuestionnaire: false,
    autoLogin: false,
    loging: false,
})
const getters = {}
const actions = {
    // 登录
    Login({ commit }, userinfo) {
        return new Promise((resolve, reject) => {
            Login(userinfo).then(res => {
                commit('SET_USER', res.loginUser)
                commit('set_loging', true)
                setToken(res.token)
                resolve()
            }).catch((error) => {
                reject(error)
            })
        })
    },
    wLogin({ commit }, userinfo) {
        return new Promise((resolve, reject) => {
            axios.post(userinfo.url, userinfo).then(({ data }) => {
                console.log(data);
                let res = data.data
                commit('SET_USER', res.loginUser)
                commit('set_loging', true)
                setToken(res.token)
                resolve()
            }).catch((error) => {
                reject(error)
            })
        })
    },
    // 退出登录
    LogOut({ commit }) {
        return new Promise((resolve) => {
            LogOut().then(() => {
                commit('RESET_USER', {})
                removeToken()
                commit('setIsShowQuestionnaire', false)
                resolve()
            })
        })
    },
    // 重置密码
    ResetPwd({ commit }, params) {
        return new Promise((resolve) => {
            ResetPwd(params).then(() => {
                commit('RESET_USER', {})
                resolve()
            })
        })
    },
    // 地区
    LoadArea({ commit }, params) {
        return new Promise((resolve) => {
            LoadArea(params).then((res) => {
                commit('SET_AREA', res)
                setArea(res)
                resolve()
            })
        })
    },
}
const mutations = {
    setAutoLogin(state, data) {
        state.autoLogin = data
    },
    setIsShowQuestionnaire(state, data) {
        state.setIsShowQuestionnaire = data
    },
    set_loging(state, data) {
        state.loging = data
    },
    SET_USER(state, data) {
        state.user = data
    },
    RESET_USER(state) {
        state.user = {}
        state.loging = false
        state.setIsShowQuestionnaire = false
        removeToken()
        removeArea()
    },
    SET_AREA(state, data) {
        state.area = data
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}