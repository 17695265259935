const TokenKey = 'auth-token'

export function getToken() {
    return localStorage.getItem(TokenKey)
}

export function setToken(token) {
    return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
    return localStorage.removeItem(TokenKey)
}

export function getArea() {
    return JSON.parse(localStorage.getItem('area'))
}

export function setArea(res) {
    return localStorage.setItem('area', JSON.stringify(res))
}

export function removeArea() {
    return localStorage.removeItem('area')
}