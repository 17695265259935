const whiteRoute = [
    {
        path: '/index',
        name: 'index',
        component: () => import('@/views/page/Index'),
        hidden: false,
        meta: {
            title: '首页', activeMenu: '/index',
        }
    },

    {
        path: '/course',
        name: 'course',
        component: () => import('@/views/page/Course'),
        hidden: false,
        meta: {
            title: '课程', activeMenu: '/course'
        }
    },
    {
        path: '/activity',
        name: 'activity',
        component: () => import('@/views/page/Activity'),
        hidden: false,
        meta: {
            title: '活动', activeMenu: '/activity'
        }
    },
    {
        path: '/EntrepreneurshipMentor',
        name: 'EntrepreneurshipMentor',
        component: () => import('@/views/page/EntrepreneurshipMentor'),
        hidden: false,
        meta: {
            title: '创业导师', activeMenu: '/EntrepreneurshipMentor'
        }
    },
    {
        path: '/policy',
        name: 'policy',
        component: () => import('@/views/page/Policy'),
        hidden: false,
        meta: {
            title: '政策', activeMenu: '/policy'
        }
    },

    {
        path: '/serve',
        name: 'serve',
        hidden: false,
        meta: {
            title: '服务', activeMenu: '/serve', children: [
                { name: '创业服务', path: '/EntrepreneurshipServices' },
                { name: '创业孵化', path: '/EntrepreneurshipIncubate' },
            ]
        },
        redirect: '/EntrepreneurshipServices',
    },
    {
        path: '/EntrepreneurshipServices',
        name: 'EntrepreneurshipServices',
        component: () => import('@/views/page/EntrepreneurshipServices'),
        hidden: true,
        meta: {
            title: '创业服务', activeMenu: '/serve'
        }
    },
    {
        path: '/EntrepreneurshipIncubate',
        name: 'EntrepreneurshipIncubate',
        component: () => import('@/views/page/EntrepreneurshipIncubate'),
        hidden: true,
        meta: {
            title: '创业孵化', activeMenu: '/serve'
        }
    },
    {
        path: '/EntrepreneurshipIncubation',
        name: 'EntrepreneurshipIncubation',
        component: () => import('@/views/page/EntrepreneurshipIncubation'),
        hidden: true,
        meta: {
            title: '服务列表', activeMenu: '/serve'
        }
    },
    {
        path: '/MentorInfo',
        name: 'MentorInfo',
        component: () => import('@/views/page/MentorInfo'),
        hidden: true,
        meta: {
            title: '导师详情', activeMenu: '/EntrepreneurshipMentor'
        }
    },

    {
        path: '/ServicesInfo',
        name: 'ServicesInfo',
        component: () => import('@/views/page/ServicesInfo'),
        hidden: true,
        meta: {
            title: '服务详情', activeMenu: '/serve'
        }
    },
    {
        path: '/IncubationInfo',
        name: 'IncubationInfo',
        component: () => import('@/views/page/IncubationInfo'),
        hidden: true,
        meta: {
            title: '孵化详情', activeMenu: '/serve'
        }
    },
    {
        path: '/policyDetail',
        name: 'policyDetail',
        component: () => import('@/views/page/PolicyDetail'),
        hidden: true,
        meta: {
            title: '政策详情', activeMenu: '/policy'
        }
    },

    {
        path: '/courseDetail',
        name: 'courseDetail',
        component: () => import('@/views/page/CourseDetail'),
        hidden: true,
        meta: {
            title: '课程详情', activeMenu: '/course'
        }
    },

    {
        path: '/activityDetail',
        name: 'activityDetail',
        component: () => import('@/views/page/ActivityDetail'),
        hidden: true,
        meta: {
            title: '活动详情', activeMenu: '/activity'
        }
    },
    {
        path: '/personalCenter',
        name: 'personalCenter',
        component: () => import('@/views/page/PersonalCenter'),
        hidden: true,
        children: [
            {
                path: '/myCourse',
                name: 'myCourse',
                component: () => import('@/views/page/MyCourse'),
                hidden: true,
            },
            {
                path: '/myActivity',
                name: 'myActivity',
                component: () => import('@/views/page/MyActivity'),
                hidden: true,
            },
            {
                path: '/myMessage',
                name: 'myMessage',
                component: () => import('@/views/page/MyMessage'),
                hidden: true,
            },
            {
                path: '/myInfo',
                name: 'myInfo',
                component: () => import('@/views/page/MyInfo'),
                hidden: true,
            },
        ]
    },
    {
        path: '/aboutUs',
        name: 'aboutUs',
        hidden: true,
        component: () => import('@/views/page/aboutUs')
    },


]
export default whiteRoute